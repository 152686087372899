import { useEffect,useState } from "react"
import X from "./svg/X";

const Modal = ({ children, onHide, isVisible=true }) => {

    const [outerClassName, setOuterClassName] = useState("-translate-y-1 opacity-0");
    const [className, setClassName] = useState("-translate-y-1 opacity-0");
    
    
    useEffect(()=>{
        if(isVisible){
            setOuterClassName("-translate-y-1 opacity-0");
        setClassName("-translate-y-1 opacity-0");
        } else{
            close();
        }
    }, [isVisible])
    useEffect(() => {
        setTimeout(() => {

            setOuterClassName("opacity-100")
            setClassName("-translate-y-0")
        }, 1)

    }, [])

    const close = (option) => {
        setOuterClassName("opacity-0")
        setClassName("-translate-y-8")


        setTimeout(() => {
            setOuterClassName(" opacity-0 hidden")
            onHide?onHide():(()=>{})();
        }, 300)
    }






    return <div className={`fixed inset-0 z-30 flex items-center min-h-screen p-4 overflow-y-auto transition ease-in-out duration-300 ${outerClassName}`}>
        <button type="button" onClick={e => close("")} className="fixed inset-0 w-full h-full bg-black/50 focus:outline-none cursor-default" />
        <div className={`relative w-full mt-auto md:mb-auto cursor-pointer pointer-events-none ease-in-out duration-300  ${className}`}>
            <div className="w-full mx-auto space-y-2 bg-dark-600 rounded-xl cursor-default pointer-events-auto  max-w-sm">
                <div className="p-4 sm:p-12 overflow-visible flex flex-col">
                    <button onClick={e => close("")} className="absolute self-end ">

                        <X className="w-4 h-4"/>
                    </button>
                {children}
                </div>
            </div>

        </div>
    </div>
}

export default Modal;