import React, { useEffect, useState }  from 'react';
import ReactDOM from 'react-dom/client';
import GoogleCallback, { LoggedIn } from "./pages/Auth";
import { BrowserRouter, Routes, Route, Navigate, useSearchParams} from "react-router-dom";
import useAuth from './hooks/useAuth';
import Layout from './components/Layout';
import Login, { SwitchCustomer } from './pages/Login';
import Home from './pages/Home';
import NotificationBar from './components/Notification';
import Cookies from "js-cookie";
import Links from './pages/Links';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Privacy, { PrivacyAnonymous } from './pages/Privacy';
import Projects, { ProjectsHeader } from './pages/Projects';
import Dashboard, { DashboardHeader } from './pages/Dashboard';
import { CreateLink, EditLink } from './pages/EditLink';
import { DeleteButton } from './components/Edit';
import { btoa } from 'react-native-quick-base64';
import Emails from './pages/Emails';
import Ads from './pages/Ads';
import Presales from './pages/Presales';
import RequestAccess from './pages/RequestAccess';
import Calls from './pages/Calls';
import Unauthorized from './pages/Unauthorized';
import Timeline from './pages/Timeline';


const routes = [
  {
    title: "Privacy",
    path: "privacy",
    id: "privacy",
    element: () => <Privacy />
  },
  {
    title: <ProjectsHeader/>,
    documentTitle:"Projects",
    path: "projects",
    id: "projects",
    element: () => <Projects />
  },
  {
    title: "Timeline",
    path: "timeline",
    id: "timeline",
    element: () => <Timeline />
  },
  {
    title: "Emails",
    documentTitle:"Emails",
    path: "emails",
    id: "emails",
    element: () => <Emails />
  },
  {
    title: "Ads",
    path: "ads",
    id: "ads",
    element: () => <Ads />
  },
  {
    title: "Presales",
    path: "presales",
    id: "presales",
    element: () => <Presales />
  },
  {
    title: <DashboardHeader/>,
    documentTitle:"Dashboard",
    path: "dashboard",
    id: "dashboard",
    element: () => <Dashboard />
  },
  {
    title: <DashboardHeader calls/>,
    documentTitle:"Calls",
    path: "calls",
    id: "calls",
    element: () => <Calls />
  },
  {
    title: "Create link",
    path: "links/create",
    id: "linkcreate",
    element: () => <CreateLink />
  },
  {
    title: "Edit link",
    path: "links/:id/edit",
    id: "linkedit",
    buttons:<DeleteButton title="link" url="links">Delete</DeleteButton>,
    element: () => <EditLink />
  }
]

export default function App() {
  const [userInfo, checkAuth] = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(!userInfo){
      checkAuth(r=>setLoading(false));
    } else{
      setLoading(false);
      
    }
  }, [userInfo])

  

  let redirect;

  if(!userInfo)
  {
    redirect=btoa(`${window.location.pathname}${window.location.search}`);
  }

 
  
  
  return (
    <BrowserRouter>

      <Routes>
        <Route path="logged-in" element={<LoggedIn/>}/>
        <Route path="request-access" element={<RequestAccess/>}/>
        <Route path="unauthorized" element={<Unauthorized/>}/>
        
            {
              !userInfo?<Route path="/auth">
                <Route path="google-callback" element={<GoogleCallback/>}/>
              
            </Route>:<></>
            }
        {
          !userInfo  ?


            <>
                <Route path="/privacy" element={<PrivacyAnonymous/>}/>

            {
               !loading&&<><Route path="*" >
               <Route path="*" element={<Navigate to={`/login?rd=${redirect}`} />} />
             </Route>
             <Route path="">
               <Route path="*" element={<Navigate to={`/login?rd=${redirect}`} />} />
               <Route index={true} path="login" element={<Login />} />
               <Route path="" element={<Navigate to={`/login?rd=${redirect}`} />} />
              
             </Route></>
            }
            
              
            </>
            :
            <>
              <Route path="" >
                <Route path="/"  element={<Navigate to="/links"/>} />
                <Route path="/links" index={true} element={<Layout active="links" title="Links" buttons={<a href='/links/create' className='inline-flex items-center justify-center font-medium tracking-tight rounded-lg border transition-colors focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset h-9 px-4 text-white shadow focus:ring-white border-transparent bg-primary-600 hover:bg-primary-500 focus:bg-primary-700 focus:ring-offset-primary-700'><span>Create Link</span></a>}><Links/></Layout>} />
                {
                  routes.map((r, index) => {


                    
                    if (r.layout) {
                      return <Route key={index} path={r.path} element={<r.layout active={r.id} title={r.title}><r.element /></r.layout>} />
                    } else {
                      return <Route key={index} path={r.path} element={<Layout buttons={r.buttons} active={r.id} title={r.title} documentTitle={r.documentTitle}><r.element /></Layout>} />
                    }

                  })
                }
                {
                  userInfo.admin&&<Route path="switch" element={<SwitchCustomer/>}/>
                }
                

              </Route>

              <Route path="*" >
                <Route path="*" element={<Navigate to="/links" />} />
              </Route>
            </>
        }


      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<><App /><NotificationBar /></>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
