import React, { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import Popup from "./Popup";
import MessageBox from "./MessageBox";
import { sendNotification } from "./Notification";
import jPath from "../scripts/jPath";
import { Link } from "react-router-dom";
import safeArrayMap from "../scripts/safeArrayMap";
import './MultiDimensionalDataTable.css';
import PlusSquare from "./svg/PlusSquare";
import MinusSquare from "./svg/MinusSquare";

const MultiDimTable = ({children, items, filter, loading, autoExpand, filterLevel, showIfChild=true}) =>{
    
   const [filters, setFilters] = useState([]);
   const [order, setOrder] = useState({direction:1});


   const updateFilters = (filterValues) =>{
    let newFilters=Object.entries(filterValues).map(([key, value], index)=>{
     
        if(Array.isArray(value)){
            if(value.length===0){
                return (item)=>true;
            } else{
                return (item)=>value.includes(item[key])
            }
        } else if(value?.type==="dateRange"){
            let endDate;
            if(value.startDate){
                let startDate = Date.parse(value.startDate);
                if(value.endDate){
                    endDate = Date.parse(value.endDate);
                    return (item)=>{
                        if(!item[key]){
                            return false
                        }
                        
                        let itemDate = Date.parse(item[key]);
                       
                        
                        return itemDate>=startDate&&itemDate<=endDate;
                    }
                }
                return (item)=>item[key]&&Date.parse(item[key])>=startDate;


            } else if(value.endDate){
                endDate = Date.parse(value.endDate);

                return (item)=>item[key]&&Date.parse(item[key])<=endDate;
            }
            
            return (item)=>true;
        }else{
            if(!value){
                return (item)=>true;

            } else{
                return (item)=>item[key]==value;

            }
        }
    });
    setFilters(newFilters);
   }

    return <div className="bg-dark-600 rounded-xl shadow-sm border border-dark-300">
        {filter&&<div className="flex items-center justify-end p-2 h-14 border-b border-dark-300">
            {React.cloneElement(filter, {onChange:updateFilters})}
            </div>}
            {
                <MultiDimTableBody filterLevel={filterLevel} showIfChild={showIfChild} hasFilter={filter?true:false} loading={loading} orderState={[order, setOrder]} autoExpand={autoExpand} items={items} filters={filters}>{children}</MultiDimTableBody>
                
            }
        
    </div>;
}

const filterItems = (items, filters, order, level=0, showIfChild=true, filterLevel) =>{


    if(!items||!Array.isArray(items)||items.length===0){
        return [];
    }
    for (let index = 0; index < filters.length; index++) {
        
        items=items.filter(item=>{
            if(item.items&&(showIfChild||level<filterLevel)){
                let childItems = item.items;
                if(childItems.$values){
                    childItems=childItems.$values;
                }
                return (filters[index](item)||filterItems(childItems, filters, order, level+1, showIfChild, filterLevel).length>0);
            } else{

                return filters[index](item);
            }
            
        })

        
    }
    if(order?.field){
        items.sort((a,b)=>(a[order.field]>b[order.field]?1:-1)*order.direction)

    }
    return items;
}


const MultiDimTableRow = ({children,items, columnCount, level, filters, autoExpand, orderState, filterLevel, showIfChild}) =>{
    const [shouldDisplay, setShouldDisplay] = useState(false);
    const [childrenConst, setChildrenConst] = useState([]);
    const [order,]=orderState;

    level??=0;
    useEffect(()=>{
        if(children){
            setChildrenConst(children)
        }
    },[children])
    const recursiveAutoExpand = (item) => {
        if(autoExpand(item)){
            return true;
        }
        let values = item.items?.$values??item.items;
        if(values&&values.find(recursiveAutoExpand)){
            return true;
        }
    }
    useEffect(()=>{
        if(autoExpand){
            let values = items[0].items?.$values??items[0].items;
            if(values&&values.find(recursiveAutoExpand)){
                setShouldDisplay(true)
            }
        }
    }, [items])
    let item = items[0];
    let nestedItems;
    return <>
    <div className="contents group">
    {
        safeArrayMap(childrenConst, ({props}, i)=>{
            
            let value = props.field? item[props.field]:item;
            
            if(!value&&value!==false){
                return <div key={i}  className={`px-4 py-3 ${props.className}`}></div>
            }

            if(props.nested){
                
                if(!Array.isArray(value)){
                    
                    value=value.$values;
                    value=filterItems(value, filters, order, level+1, showIfChild, filterLevel);
                    nestedItems=value;
                } else{
                    value=filterItems(value,filters,order, level+1, showIfChild, filterLevel)
                    nestedItems=value;
                }
                if(value.length===0){
                    return <div key={i}  className=""></div>;
                }
                
                return <button key={i} onClick={e=>setShouldDisplay(!shouldDisplay)} style={{fontWeight: 600-200*level, left:`${32*level}px`, position:"relative", gridColumnStart:1}} className="w-full justify-end flex items-center  px-2 md:pr-0 md:pl-4">{shouldDisplay?<MinusSquare className="w-4 h-4 text-gray-600 m-0"/>:<PlusSquare className="w-4 h-4 m-0 text-gray-600"/>}</button>
            }

            let displayValue = props.display??((value)=><>{value}</>);
            return <div title={value} style={{fontWeight: 600-200*level, left: `${i==1?32*level:0}px`, position:"relative", width:`calc(100% - ${i==1?32*level:0}px)`}} key={i} onClick={e=>props.onClick??((v)=>{})(value)}  className={`md:px-4 py-3 pivot-col${i} pivot-lv${level} ${props.className}`}>{displayValue(value)}</div>
        })
    }
    <div className="bg-dark-300 spacer" style={{height:"1px", gridColumnEnd: columnCount+1, gridColumnStart:1}}></div>
    </div>
    {
        shouldDisplay&&nestedItems?.map((item,index)=>{
            return <MultiDimTableRow filterLevel={filterLevel} showIfChild={showIfChild} orderState={orderState} autoExpand={autoExpand} filters={filters} key={columnCount+index} items={[item]} children={children} columnCount={columnCount} level={level+1}/>
        })
     
    }
</>
}
const MultiDimTableBody = ({children, items, filters, autoExpand,orderState, loading, hasFilter, showIfChild, filterLevel}) =>{
    let cCount = children?1:0;

    const [order, setOrder] = orderState;
    const [filteredItems, setFilteredItems]  = useState([]);

    useEffect(()=>{
        if(items){

            setFilteredItems(filterItems(items,filters,order, 0, showIfChild,filterLevel))
        }
    },[items, order, filters])
 

    const nestedChild = children.find(c=>{
       
        return c.props.nested;
    })
   
    const toggleOrder = (field)=>{
        let newState = {
            field:field,
            direction:order.direction*-1
        }
        setOrder(newState);
    }
    

    
    if(Array.isArray(children)){
        cCount=children.length;
    }
    
    if(filteredItems.length===0){
        return  <div className="flex items-center justify-center p-4">
        <div className="flex flex-1 flex-col items-center justify-center p-6 mx-auto space-y-6 text-center bg-dark-600">
            <div className="flex items-center justify-center w-16 h-16 text-primary-500 rounded-full  bg-dark-600">
                {loading?<svg x-show="isLoading" className="animate-spin w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"></path>&gt;
                </svg>:<svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg> }
                   </div>

            <div className="max-w-xs space-y-1">
                <h2 className="text-xl font-bold tracking-tight">
                    {loading?<>Loading</>:<>No records found</>}
                </h2>

               
            </div>

        </div>
    </div>
    } 
    
    return <div style={{gridTemplateColumns:`2rem 1fr repeat(${cCount-2}, auto)`}} className={`grid divide-y overflow-x-auto` }>
        <div className={`hidden xl:contents ${!hasFilter&&"rounded-t-xl"}`}>
        {
            children.map((c,i)=>{
                let className = "";
                if(!hasFilter){
                    if(i===0){
                        className="rounded-tl-xl";

                    } else if(i===children.length-1){
                        className="rounded-tr-xl";

                    }
                }
              
                return <div key={i} className={`${className} ${c.props.className} bg-dark text-gray-300  flex items-center ${!c.props.nested&&"px-4"} py-2 whitespace-nowrap space-x-1 rtl:space-x-reverse font-medium text-sm cursor-default `}>
                    
                    {
                        c.props.sortable?<button onClick={e=>toggleOrder(c.props.field)} type="button" className="flex items-center w-full px-4 py-2 whitespace-nowrap space-x-1 rtl:space-x-reverse font-medium text-sm text-gray-300 ">
                        <span>
                            {c.props.header}
                        </span>
    
    
    
                        <span className="relative flex items-center">
                            {
                                order.field == c.props.field ?
                                    <>
                                        {
                                            order.direction == 1 ?
                                                <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd"></path>
                                                </svg>
                                                : <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                        }
                                    </>
                                    : <svg className="w-3 h-3 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                            }
    
                        </span>
    
                    </button>
                    :
                    <span>{c.props.header}</span>
                    }

                </div>
            })
        }
            <div className="bg-dark-300 spacer" style={{height:"1px", gridColumnEnd: cCount+1, gridColumnStart:1}}></div>
        </div>
    {
        filteredItems.map((item, index)=>{
            
            return <MultiDimTableRow filterLevel={filterLevel} showIfChild={showIfChild} orderState={[order, setOrder]} autoExpand={autoExpand} filters={filters} key={cCount+index} items={[item]} nestedChildren={nestedChild?.props?.children} children={children} columnCount={cCount}/>
            
        })
    }
</div>
}





export { MultiDimTable };